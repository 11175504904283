export const appPageTransition = false

export const appLayoutTransition = false

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"charset","content":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"stylesheet","href":"/vstatic/bootstrap-5.3/bootstrap.min.css"},{"rel":"stylesheet","href":"/vstatic/css/styles.css?ts=1726532398740"}],"style":[],"script":[{"src":"/vstatic/bootstrap-5.3/bootstrap.bundle.min.js"},{"src":"/vstatic/javascript/our_jquery_dependent_code.js?ts=1726532398740"},{"src":"https://kit.fontawesome.com/b432aaf6cf.js"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'